@media screen and (max-width: 1100px) {
	.nav.navbar-nav li a{font-size: 14px; padding:15px 0px;}
	.testimonials-text{padding: 0px;}
	.pinned-logo{margin-right:0px;}
	.home-testimonials-box{padding: 50px 120px;}
	/*.lang, #menu-container.pinned  .lang{margin-top: 14px !important;}*/
	.scrolled-nav .pinned-logo{margin-top: -5px;}
	.scrolled-nav .nav.navbar-nav{margin-top: 10px;}

}

@media screen and (max-width: 780px){
	/*homepage*/
	.nav.navbar-nav{display: block !important; padding: 0px 40px; margin-top: 0;}
	.nav.navbar-nav li a{font-size: 22px; letter-spacing: 3px; font-weight: normal; padding: 40px 0px 0px 0px; color: #fff !important;}
	.top-menu{height:70px;}
	.home-about-text{padding-left:0px;}
	#contact-page, #reservation-page, #delivery-page, #news-page, #menu-page, #testimonials-page{margin-top: 70px;}
	.mobile-home-buttons{display: block; overflow: hidden; margin-top: 90px;}
	.home-logo img{max-width: 200px; margin-top: 80px;}
	.navbar-toggle.collapsed{border: none; margin-top: 8px; margin-right: 17px;}
	#menu-container.pinned li.menu-logo{display: none !important;}
	.top-menu{background-color: #1d1e1e !important;}
	.navbar-default .navbar-toggle .icon-bar, .icon-bar2, .icon-bar3{background-color: #fff;}
	.navbar-toggle .icon-bar, .icon-bar2, .icon-bar3{display: block; width: 35px; height: 4px; border-radius: 2px; margin-top: 7px;}
	.navbar-toggle .icon-bar+.icon-bar{margin-top: 7px;}
	.icon-bar.active{transform: rotate(45deg);  -webkit-transition: 300ms ease all; -moz-transition: 300ms ease all; -o-transition: 300ms ease all; transition: 300ms ease all;}
	.icon-bar2.active{transform: rotate(-45deg); margin-top: -4px;  -webkit-transition: 300ms ease all; -moz-transition: 300ms ease all; -o-transition: 300ms ease all; transition: 300ms ease all;}
	.icon-bar3.active{display: none;}
	.navbar-default .navbar-toggle:hover{background-color: transparent;}
	.navbar-default .navbar-toggle:hover .icon-bar{background-color: #434a4c;}
	.navbar-default .navbar-toggle:hover .icon-bar2{background-color: #434a4c;}
	.navbar-default .navbar-toggle:hover .icon-bar3{background-color: #434a4c;}
	.mobile-home-btn{ height: 134px; border-radius: 4px; background-color: rgba(255, 255, 255, 0.87); box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.45); position: relative; margin-bottom: 25px; display: flex; align-items: center;}
	.calendar-icon, .delivery-icon, .pin-icon, .tel-icon{width: 100%;}
	.mobile-home-btn span{font-size: 17px;     padding: 0px 6px;  font-weight: bold; letter-spacing: 2px; text-align: center; color: #576a2f; display: block; margin-top: 24px;	font-family: HypatiaSansPro;}
	.mobile-home-btn img{display: block;margin: 0 auto;}

	#mobile-menu{background-color: #1d1e1e; height: 100%; position: fixed; left: 0; right: 0; border: 0; box-shadow: none; margin-top: -1px;}
	.navbar-toggle ~ .navbar-header{  background-color: #1d1e1e;}
	.navbar-toggle{border: 0px; margin-top: 17px; margin-right: 17px;}
	.mobile-top-menu.active-top-menu{display: block;}
	.mobile-top-menu{display:none; margin-left: 40px;}
	.mobile-top-menu ul{padding: 0; margin-top: 27px;}
	.mobile-top-menu ul li{float: left;}
	.mobile-top-menu ul li a{font-size: 13px; font-weight: bold; letter-spacing: 1px; color: #d5d5d5; padding: 13px 33px !important; border-radius: 22px;}
	.mobile-search{margin-left: 20px; margin-top: -10px;}
	.mobile-lang a{border: solid 1px #d5d5d5; padding: 13px 33px; border-radius: 22px;}
	.mobile-search a{border: solid 1px #d5d5d5; padding: 13px 14px; border-radius: 22px;}
	.mobile-logo{
		max-width: 170px;
		height: 80px;
		background-color: #434a4c;
		box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2);
		display: block;
		margin: 0 auto;
		overflow: visible;}
	.mobile-logo img{
		margin: 0 auto;
		display: block;
		padding-top: 10px;
	}
	.top-menu.scrolled-nav .mobile-logo{height: 60px; transition: height .5s, line-height .5s;}
	.top-menu.scrolled-nav .mobile-logo img{padding-top: 7px; max-width: 100px; transition: height .5s, line-height .5s;}
		.mobile-logo.hidden{display: none;}

	/*footer*/

	.footer-bg-image{background: #1d1e1e !important; height: initial;}
	.footer-overlay, .instagram-images{display: none;}
	.copyright-wrap{background-color: #1d1e1e;}
	.newsletter-form input{height: 50px; border-radius: 25px; 	border: solid 1px #646b6d; width: calc(100% - 30px);}
	.footer-newsletter h4{color: #fff; font-size: 22px;}
	.footer-contact-info h5{font-size: 22px; color: #fff;}
	.footer-container{min-height: 90px; background-color: #576a2f;}
	.social-footer ul{margin-top: 30px;}
	.social-footer ul li a{font-size: 25px;}
	.map-icon a{margin-left: 0;}


	/*contact page*/
	.static-paga-title h1{font-size: 30px !important; margin-bottom: 0px; letter-spacing: 4px !important;}
	/*.contact-info-wrap, .contact-form h2{display: none;}*/
	#doall input, #doall textarea{min-height: 60px;}
	.form-send, .recaptcha{text-align: center;}
	.contact-info-wrap, .contact-form{margin-top: 19px;}


	/*testimonials*/
	.testimonial{min-height: 400px;}
	.testimonial-text{padding:0px 30px;}
	.testimonial-text h2{font-size: 17px; text-align: center; letter-spacing: 1px;}
	.posted-on{font-size: 18px; text-align: center;}
	.posted-icon{margin-top: 20px; text-align: center; padding-right: 0px;}

	/*delivery page*/
	.delivery-scooter{display: none;}
	.delivery-text{padding:0px;}
	.delivery-container{min-height: initial;}
	.delivery-info-wrap{min-height: initial; margin-bottom: 20px; text-align: center;}
	.right-map{height: 450px;}
	.delivery-map h4{display: block;}
	#delivery-page{padding-bottom: 0px;}

	/*menu page*/
	.food-menu-tabs{display: block;}
	.food-menu-tabs li:not(.active){display: none;}
	.menu-tabs-content{margin-top: 20px;}
	.product-info p{text-align: left;}
	.menu-desc{margin-bottom: 10px; padding-right: 40px;}
	#reservation-form input::placeholder, #doall input::placeholder{line-height: 20px;}

	/*reservation page*/
	#reservation-form input, #reservation-form textarea{min-height: 60px;}
	.thessaloniki-map{min-height: 400px;}
	.reservation-time, .people-reservation{height: 60px; margin-top: 10px;}
	.date-picker{max-height: 60px;}
	.date-picker .input{height: 60px;}
	.date-picker .input .result{height: 60px; line-height: 60px;}
	.reservation-time select, .people-reservation select, .date-picker{background-position: calc(100% - 20px) calc(1em + 12px), calc(100% - 15px) calc(1em + 12px), calc(100% - 2.5em) 0.5em;}
	.reservation-time label, .people-reservation label{line-height: 60px;}
	#smoking-table, #no-smoking-table{width: 40px; min-height: 40px !important; border-radius: 20px !important;}
	#no-smoking-table:checked:after, #smoking-table:checked:after{
		content: " ";
	    border-radius: 10px;
	    line-height: 40px;
	    margin: 0 auto;
	    margin-top: 9px;
	    width: 20px;
	    height: 20px;
	    background-color: #576a2f;
	    display: block;
	}
	#no-smoking-table{margin-left: 10px;}


	/*news page*/
	.news-bg-image{height: 400px;}
	.news-title h2{padding: 0px 40px;}
	.news-title h2 a{padding:0px;}


	/*restaurants page*/
	.restaurant-img img{max-width:100%; height: auto;}
	#mobile-search-form input[type=text] {
		width: 0px;
		padding-left: 10px;
		color: transparent;
		cursor: pointer;
	}

	#mobile-search-form input[type=text]:focus {
		color: #fff;
		background-color: #1d1e1e;
		cursor: auto;
		width: 190px;
		padding: 10px 10px;
		outline: none;
		background-position-x: 160px;
	}
	#mobile-search-form input:-moz-placeholder {color: #fff;}
	#mobile-search-form input::-webkit-input-placeholder {color:#fff;}

 	#mobile-search-form input[type=text] {	
		background: #ededed url('../img/search-icon.png') no-repeat center;
		border: 1px solid #d5d5d5;
		background-color: transparent;
		padding: 11px 10px 10px 32px;
		border-radius: 22px;
		-webkit-transition: all .5s;
		-moz-transition: all .5s;
		transition: all .5s;
}

	#mobile-search-form input:-moz-placeholder {color: #fff;}
	#mobile-search-form input::-webkit-input-placeholder {color: #fff;}

	/*single post*/
	.post-share-it{text-align: center;}
	.post-share-it ul{display: flex; justify-content: center; margin-top: 30px;}
	.post-share-it h3{display: none;}
	.post-share-it{min-height: 90px;}
	#single-post{margin-top: 70px;}
	.single-post-img{height: 300px;}
	.more-posts{margin-bottom: 0px;}
	.more-articles{margin-bottom: 80px;}
	/*.more-articles{display: none;}*/
	.lang{margin-top: 0px !important;}
	.menu-title{font-size: 22px; letter-spacing: 2px;}
	#top-section{height: 100vh; overflow-y: hidden; min-height: inherit;}
	#menu-container.pinned .top-menu{box-shadow: none;}
	.nav.navbar-nav a:before, .nav.navbar-nav a::after{background-color: transparent !important;}
	.nav.navbar-nav a:before, .nav.navbar-nav a::after{display: none;}
	.static-paga-title{margin-bottom: 20px;}
	.contact-info-wrap{padding-right: 0px;}
	.mobile-home-footer{
		display: block;
		position: fixed;
		bottom: 0px;
		left: 0;
		right: 0;
		text-align: center;
		background-color: rgba(255, 255, 255, 0.87);
		padding: 20px 0px;
	}
	.mobile-home-footer a img{
		max-height: 25px;
		margin-right: 10px;
	}
	.mobile-home-footer a{
		font-size: 17px;
		font-weight: bold;
		letter-spacing: 2px;
		text-align: center;
		color: #576a2f;
		display: block;
		font-family: HypatiaSansPro;
	}
}

@media screen and (max-width: 380px){
	#smoking-table, #no-smoking-table{width: 30px; min-height: 30px !important; border-radius: 15px !important;}
	#no-smoking-table:checked:after, #smoking-table:checked:after{border-radius: 8px; line-height: 30px; margin-top: 6px; width: 15px; height: 15px;}
	.smoking-check label{font-size: 12px; margin-left: 1px;}
	.mobile-top-menu{margin-left: 20px;}
	.mobile-lang a{padding:10px 28px;}
	.home-logo img{max-width: 200px; margin-top: 50px;}
	#mobile-search-form input[type=text]:focus{width: 170px; background-position-x: 140px;}
	.contact-desc{font-size: 14px;}
	
}

@media screen and (max-width: 320px){
	.home-logo img{max-width: 250px; margin-top: 60px;}
	.mobile-home-buttons{margin-top: 40px;}
	.mobile-home-btn span{font-size: 15px; margin-top: 20px;}

}
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900');
body {
    width: 100%;
    height: 100%;
    font-family: 'Lato', sans-serif;
    color:#434a4c;
    background-color: #fff;
    -webkit-font-smoothing: smooth-antialiased !important;
    line-height: 1.55;
    overflow-x: hidden;
    outline: 0;
}

html {
    width: 100%;
    height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
    font-family: hypatia-sans-pro, sans-serif;

}


strong{
    font-weight: bold;
}
/*.pinned-logo{display: none;}*/
.abovemenu { position: relative; }
.pinned-logo {
    opacity: 0;
    margin-top: -40px;
    margin-right: 110px;
    -webkit-transition: all .6s ease-in-out;
    -moz-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
}
/*scroll menu*/
li.menu-logo{display: none; -webkit-transition: all .6s ease-in-out; -moz-transition: all .6s ease-in-out; transition: all .6s ease-in-out;}
#menu-container.pinned li.menu-logo { display: block;  }
#menu-container.pinned .pinned-logo { opacity: 1;    -webkit-transition: all .5s; transition: all .5s;}
.scrolled-nav .pinned-logo a img{display: inline-block; -webkit-transition: all .5s; transition: all .5s; max-width: 100px;}
.scrolled-nav .nav.navbar-nav{margin-top: 20px;}
.scrolled-nav .pinned-logo{margin-top:-16px;}
.lang a:hover{border-color: #576a2f;}
.scrolled-nav {height: 60px !important;}
#menu-container.pinned .top-menu{box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);}
#menu-container.pinned .right-menu{padding:0 !important;}
#menu-container.pinned .right-menu>li{padding: 10px 10px !important;}
#menu-container.pinned  .lang{margin-top: 0px !important;}
#menu-container.pinned .nav.navbar-nav a:before {background-color: #434a4c;}
#menu-container.pinned .nav.navbar-nav a::after{background-color: #434a4c;}

a {
    color: #221e1f;
    outline: 0;
    text-decoration: none !important;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
a:hover{
     -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    text-decoration: none;
    color: #979797;
}
ul li{
    list-style: none;
}
.row.is-flex {
    display: flex;
    flex-wrap: wrap;
}
.row.is-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.row.is-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.row.is-flex > [class*='col-'] {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;


}
.row.is-flex:before { display: block; }
/*top-menu*/
.top-menu{
    position:fixed;
    z-index: 9999;
    height: 130px;
    /*line-height: 130px;*/
    left: 0;
    right: 0;
    top: 0;
    -webkit-transition: height .5s, line-height .5s; /* Safari */
    transition: height .5s, line-height .5s;
}
.navbar.navbar-default{
    background-color: transparent;
    border: none;
    margin-bottom: 0px;
    z-index: 9999;
}
.nav.navbar-nav{
    display: flex;
    margin-top: 60px;
    float: none;
    justify-content: space-around;
      transition: all 0.35s ease;
}
.right-menu{display: block; overflow: hidden; }
.right-menu>li{
    float: left;
    display: block;
    position: relative;
    padding: 10px 30px;
}


.nav.navbar-nav li:first-child a {
  margin-left: 0;
}

.nav.navbar-nav li.active > a {
  background-color: transparent;
}

.nav.navbar-nav a:before,
.nav.navbar-nav a::after {
  height: 1px;
  position: absolute;
  content: '';
  transition: all 0.35s ease;
  background-color: #fff;
  width: 0;
}

.nav.navbar-nava::before {
  top: 0;
  left: 0;
}

.nav.navbar-nav a::after {
  bottom: 0;
  right: 0;
}

.nav.navbar-nav a:hover,
.nav.navbar-nav li.active > a {
  color: #fff;
  transition: all 0.35s ease;
}

.nav.navbar-nav a:hover::before,
.nav.navbar-nav .active a::before,
.nav.navbar-nav a:hover::after,
.nav.navbar-nav .active a::after {
  width: 100%;
   color: #fff;
}

.pinned-logo a:hover::before,.pinned-logo a:hover::after{width:0 !important;}
.lang a:hover::before, .lang a:hover::after{width:0 !important;}

.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus{color: #fff;}
/*.lang{border: 1px solid #fff; padding: 0px 8px !important; border-radius: 21px; margin-top: 10px; max-height: 25px; line-height: 1;}*/
/*.lang a{padding: 0 !important; line-height: 22px !important;}*/
/*search input menu*/

.fade-screen {
  background: rgba(0, 0, 0, 0.4);
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.menu-logo.hidden{display: block !important; float: left; animation-name: unset !important; margin-left: 45px !important; }
.menu-logo.hidden .pinned-logo{opacity: 1;}
@-webkit-keyframes specialFadeIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  to {
    opacity: 1;
  }
}

@keyframes specialFadeIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  to {
    opacity: 1;
  }
}
.specialFadeIn {
  -webkit-animation-name: specialFadeIn;
          animation-name: specialFadeIn;
}

@-webkit-keyframes specialFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}

@keyframes specialFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}
.specialFadeOut {
  -webkit-animation-name: specialFadeOut;
          animation-name: specialFadeOut;
}

@-webkit-keyframes specialSlideInRight {
  from {
    -webkit-transform: translate3d(10%, 0, 0);
            transform: translate3d(10%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes specialSlideInRight {
  from {
    -webkit-transform: translate3d(10%, 0, 0);
            transform: translate3d(10%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.specialSlideInRight {
  -webkit-animation-name: specialSlideInRight;
          animation-name: specialSlideInRight;
}

@-webkit-keyframes specialFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
            transform: translate3d(10%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes specialFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
            transform: translate3d(10%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.spceialFadeInRight {
  -webkit-animation-name: specialFadeInRight;
          animation-name: specialFadeInRight;
}

.menu-search-container.active {
  display: block;
  -webkit-animation-name: specialFadeOut;
          animation-name: specialFadeOut;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.visible {
  visibility: visible;
}

.hidden {
  display: none;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.navbar-nav ul li {
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.navbar-nav li.is-closed {
  -webkit-animation-name: specialFadeIn;
          animation-name: specialFadeIn;
  -webkit-animation-delay: 30ms;
          animation-delay: 30ms;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.navbar-nav li.hidden {
  -webkit-animation-name: specialFadeOut;
          animation-name: specialFadeOut;
  -webkit-animation-delay: 245ms;
          animation-delay: 245ms;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.menu-search-input {
  -webkit-animation-name: specialFadeInRight;
          animation-name: specialFadeInRight;
  -webkit-animation-duration: 400ms;
          animation-duration: 400ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-delay: 350ms;
          animation-delay: 350ms;
}


.menu-search-container {
  display: none;
  position: absolute;
  top: 50%;
  left: 20.6667%;
  width: 56.66667%;
  z-index: 2;
}
.menu-search-container input {
  background-color: transparent;
  border: none;
  width: 80%;
  font-size: 16px;
  outline: none;
  color: #221e1f;
}
.menu-search-container a {
  color: #221e1f;
}
.menu-search-container a:first-child {
  display: block;
  float: left;
  padding-right: 10px;
}
.menu-search-container.active {
  display: block;
}

.menu-search-close {
  position: absolute;
  right: -183px;
  top: 0;
}



.full-bg-img{
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

/*mouse scroll icon*/
/*.scroll-mouse{position: relative; margin-top:40px;}*/
.mouse {
  position: absolute;
  width: 22px;
  height: 42px;
  bottom: 40px;
  left: 50%;
  margin-left: -11px;
  border-radius: 15px;
  border: 1px solid #fff;
  -webkit-animation: intro 1s;
          animation: intro 1s;
}
.scroll {
  display: block;
  width: 1px;
  height: 3px;
  margin: 6px auto;
  border-radius: 4px;
  background: #fff;
  -webkit-animation: finger 1s infinite;
          animation: finger 1s infinite;
}
@-webkit-keyframes intro {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes intro {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes finger {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
}
@keyframes finger {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
}
#top-section{position: relative; height: auto; width: 100%; min-height: 100vh;}
.nav.navbar-nav li a{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 25px;
    padding: 0;
    font-family: hypatia-sans-pro, sans-serif;
    color: #ffffff;
}
.lang a{border: 1px solid #fff; border-radius: 14px; padding: 0px 9px !important; line-height: 25px !important;}

.home-logo img{
    margin: 0 auto;
    display: block;
    max-width: 550px;
    margin-top: 220px;
}
.bg-button{
    display: flex;
    justify-content: center;
    margin-top: 250px;
    margin-bottom: 100px;
}
.book-btn:hover, .delivery-btn:hover{background-color:  #aa5d52;}
.book-btn, .delivery-btn{
    width: 200px;
    height: 45px;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.39);
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
}
.book-btn a, .delivery-btn a{
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    color: #ffffff;
    padding: 12px 0px;
    font-family: hypatia-sans-pro, sans-serif;
    display: block ;
}

/*homepage about*/
.about-img-right{width: 100%;height: 780px;}
.home-about-img{padding-right: 0px;}
.home-about-text{margin-top: 160px; padding-left: 50px;}
.home-about-text h2{font-size: 40px; font-weight: bold; letter-spacing: 9px; color: #576a2f; position: relative; font-family: hypatia-sans-pro, sans-serif;}
.home-about-text p{ font-size: 15px;line-height: 1.4;letter-spacing: 1px;color: #717677;}
.home-about-text h2:before{content: " "; width: 155px; height: 2px; background-color: #576a2f; display: block; position: absolute; left: -43%; margin-top: 17px;}

.newsletter-form input:focus{
  border-color: #fff;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, 0.6);
}
/*homepage menu*/
.menu-home-left{height: 640px;background-color: #f7f9f4; margin-top: -100px; z-index: -1;}
/*.home-menu-section{margin-top: -100px; z-index: -1; position: relative;}*/
.menu-home-desc{padding-top: 100px;}
.menu-home-desc h2{font-size: 40px; font-weight: bold; letter-spacing: 9px; color: #576a2f; position: relative; font-family: hypatia-sans-pro, sans-serif;}
.menu-home-desc h2:before{content: " "; width: 155px; height: 2px; background-color: #576a2f; display: block; position: absolute; left: -30%; margin-top: 17px;}
.menu-home-desc p{font-size: 15px; line-height: 1.4; color: #717677; font-family: 'Lato', sans-serif; padding: 40px 100px 60px 0px;}
.read-menu-btn{text-align: center; margin-top: 120px;}
.read-menu-btn a{background-color: #aa5d52; padding: 12px 24px; border-radius: 20px; font-size: 13px; font-weight: bold; letter-spacing: 2px; text-align: center; color: #ffffff;}
.read-menu-btn a:hover{background-color: #576a2f;}
.months-choice{margin-top: -250px;}
.months-choice h3{font-size: 24px; line-height: 1.33; letter-spacing: 4px; color: #434a4c; margin-bottom: 30px; padding: 0px 10px; font-family: hypatia-sans-pro, sans-serif;}
.month-plate-img{overflow: hidden;}
.month-plate-img img{margin: 0 auto;}
.month-plate-desc{margin-top: 30px;}
.month-plate-desc h4{font-size: 20px; font-weight: bold; letter-spacing: 1px; color: #434a4c; font-family: 'Lato', sans-serif;}
.month-plate-desc h4 a{color: #434a4c;}
.month-plate-desc h4 a:hover{color: #576a2f;}
.month-plate-desc p{font-size: 15px; line-height: 1.4; letter-spacing: 1px; color: #717677;}
.hover-zoom:hover, .hover-zoom:focus, .hover-zoom:active{-webkit-transform: scale(1.1);transform: scale(1.1);}
.hover-zoom{
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    -webkit-transition-duration: 4.0s;
    transition-duration: 4.0s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-backface-visibility: hidden;
    /* -webkit-transform: translateZ(0) scale(1.0, 1.0);*/
}

/*home boxes delivery testimonials*/
.homepage-boxes{margin-top: 190px; margin-bottom: 100px;}
.left-delivery-box, .right-testimonials-box{padding: 0px;}
.home-delivery-box{
    min-height: 640px;
    background-color: #f7f9f4;
}
.home-testimonials-box{
    min-height: 620px;
    background-color: #818c48;
    margin-top:150px;
}
.delivery-map h4{display: none;   font-size: 16px; font-weight: bold; letter-spacing: 1px; text-align: center; color: #434a4c; font-family: hypatia-sans-pro, sans-serif;}
.scooter-icon{float: right; margin-top: -60px; margin-right: 100px;}
.delivery-text{padding-top: 20px;}
.delivery-text p{ padding: 20px 100px 80px 0px; font-size: 15px; line-height: 1.4; letter-spacing: 1px; color: #717677;}
.delivery-text h2{font-size: 40px; font-weight: bold; letter-spacing: 9px; color: #576a2f; position: relative; font-family: hypatia-sans-pro, sans-serif;}
.delivery-text h2:before{content: " "; width: 155px; height: 2px; background-color: #576a2f; display: block; position: absolute; left: -40%; margin-top: 17px;}
.delivery-text h3{  font-size: 24px; line-height: 1.33; letter-spacing: 4px; color: #434a4c; font-family: hypatia-sans-pro, sans-serif;}
.delivery-area-btn{text-align: right; margin-right: 100px;}
.delivery-area-btn a{font-size: 13px; font-weight: bold; letter-spacing: 2px; text-align: center; color: #ffffff; background-color: #aa5d52; padding: 12px 27px; border-radius: 20px; font-family: hypatia-sans-pro, sans-serif;}
.delivery-area-btn a:hover{background-color: #576a2f;}
.testimonials-btn a:hover{background-color:#aa5d52; border-color: #aa5d52;}
.home-testimonials-box{margin-left: -90px; z-index: 1; position: relative;}
.left-delivery-box{position: relative; z-index: 2;}
.quote-left{position: absolute; left: 115px; top: 30px;}
.quote-right{position: absolute; right: 40px; bottom: 90px;}
.testimonials-text{padding: 120px 150px 0px 220px;}
.testimonials-text h2{font-size: 40px; font-weight: bold; letter-spacing: 9px; color: #ffffff; font-family: hypatia-sans-pro, sans-serif;}
.testimonials-text p{font-size: 24px; line-height: 1.33; letter-spacing: 3px; color: #ffffff; font-family: hypatia-sans-pro, sans-serif;}
.testimonials-text span{font-size: 20px;letter-spacing: 1px;color: #ffffff;}
.testimonials-btn{margin-top: 70px; text-align:right;padding-bottom: 100px;}
.testimonials-btn a{border: solid 1px #afb689; font-size: 13px; font-weight: bold; letter-spacing: 2px; color: #ffffff; padding: 11px 40px; border-radius: 20px; font-family: hypatia-sans-pro, sans-serif;}


/*footer*/
/*.instagram-images{margin-top: 100px;}*/
.footer-container{min-height: 140px;background-color: #0e1010;}
.footer-logo img{width: 160px; margin-top: 30px;}
.social-footer ul{display: flex; justify-content:start; margin-top: 60px; padding:0px;}
.social-footer ul li{margin-left: 70px;}
.social-footer ul li:first-child{margin-left: 0px;}
.social-footer ul li a{color: #fff; font-size: 22px;}
.social-footer ul li a:hover{color: #576a2f;}
.footer-bg-image{width: 100%; height: 550px; position: relative ;  background-position: center; background-repeat: no-repeat;}
.footer-overlay{height: 550px; opacity: 0.92; background-color: #060707; width: 100%; position: absolute; left: 0; right: 0;}
.footer-newsletter h4{font-size: 40px; font-weight: bold; letter-spacing: 9px; color: #576a2f; margin-top: 60px; margin-bottom: 4px; font-family: hypatia-sans-pro, sans-serif;}
.footer-newsletter span{font-size: 15px; line-height: 1.4; letter-spacing: 1px; color: #ffffff;}
.newsletter-form input{max-width: 496px; width: 100%; height: 40px; border-radius: 20px; background-color: rgba(163, 163, 163, 0.22); border: none; box-shadow: none; margin-top: 12px; color: #fff; text-align: right; padding: 0px 45px;}
.newsletter-form input::placeholder{font-size: 14px; line-height: 1.5; letter-spacing: 1px; color: #ffffff; text-align: right; padding-right: -60px;}
#send-newsletter{border-radius: 15px;background-color: #aa5d52; margin-left: -40px; cursor: pointer; display: inline-block; width: 30px; height: 30px; line-height: 30px; text-align: center;}
#send-newsletter:hover{background-color: #576a2f;}
#send-newsletter i{line-height: 30px;}
.newsletter-form{margin-bottom: 65px;}
.footer-info{margin-bottom: 30px;}
.footer-contact-info h5{font-size: 24px; line-height: 1.33; letter-spacing: 4px; color: #434a4c; margin-bottom: 17px; font-family: hypatia-sans-pro, sans-serif;}
.contact-tlt{font-size: 15px; letter-spacing: 1px; color: #919697; display: block;}
.contact-desc{font-size: 15px; line-height: 1.4; letter-spacing: 1px; color: #ffffff; margin-bottom: 11px; display: inline-block; font-family: 'Lato', sans-serif;}
.map-icon a{font-family: 'Lato', sans-serif; font-size: 13px; font-weight: bold; letter-spacing: 1px; text-align: center; color: #aa5d52; margin-left:0px;}
.map-icon a:hover{color: #576a2f;}
.map-icon a i{font-size: 21px;}
.copyright-wrap{height: 55px;background-color: #000000; width: 100%;}
.center-block{margin-top: 10px;}
.contactUsReponse p{font-size: 15px; line-height: 1.4; letter-spacing: 1px; color: #717677; margin-left: 10px;}
#contact-page, #reservation-page,  #delivery-page, #news-page, #menu-page, #testimonials-page{
    background-color:#f2f2f2;
    margin-top: 130px;
    padding-bottom: 110px;
    /*height: 800px;*/
}
#contact-page h1, #reservation-page h1, #delivery-page h1, #news-page h1, #menu-page h1, #testimonials-page h1{ font-size: 40px; font-weight: bold; letter-spacing: 9.4px; color: #576a2f; text-align: center; margin-top: 45px; font-family: hypatia-sans-pro, sans-serif;}
#reservation-page{padding-bottom: 400px;}
/*contact page*/
.contact-info-wrap, .contact-form{margin-top: 50px;}
.contact-info h2, .contact-form h2{font-size: 24px; line-height: 1.33; letter-spacing: 4px; color: #434a4c; margin-bottom: 30px; font-family: hypatia-sans-pro, sans-serif;}
.contact-form h2{padding-left: 10px; }
.contact-info h3{font-size: 16px; font-weight: bold; letter-spacing: 1px; color: #434a4c; margin-top: 30px; margin-bottom: 0px;  font-family: hypatia-sans-pro, sans-serif;}
.contact-info span, .email-tlt a{font-family: 'Lato', sans-serif; font-size: 15px; line-height: 1.4; letter-spacing: 1px; color: #717677;}
.contact-info ul{display: flex; justify-content:start; padding: 0; font-size: 18px; max-width: 300px; margin-top: 20px; padding-bottom: 50px; border-bottom: 2px solid #dcdcdc; margin-bottom: 50px;}
.contact-info ul li{margin-left: 30px;}
.contact-info ul li:first-child{margin-left: 0px;}
.contact-info ul li a{color: #434a4c;}
.contact-info ul li a:hover{color: #aa5d52;}
#doall input, #doall textarea {border-radius: 4px;background-color: #ffffff; border: solid 1px #dcdcdc; box-shadow: none; min-height: 40px;}
#doall input::placeholder, #doall textarea::placeholder{  font-size: 13px; font-weight: bold;letter-spacing: 1px;  color: #434a4c; font-family: hypatia-sans-pro, sans-serif;}
.form-send, .recaptcha{text-align: right; margin-top: 50px;}
#sendme{font-size: 13px; font-weight: bold; letter-spacing: 1.7px; text-align: center; color: #ffffff; font-family: hypatia-sans-pro, sans-serif; background-color: #aa5d52; padding: 13px 47px; border-radius: 20px; cursor: pointer;}
#sendme:hover{background-color: #576a2f;}
.recaptcha{margin-top: 30px; margin-bottom: 50px;}
.contact-info-wrap{padding-right: 60px;}


/*reservation */
#reservation-form input::placeholder{font-size: 13px; font-weight: bold;letter-spacing: 1px;  color: #434a4c; font-family: hypatia-sans-pro, sans-serif;}
#reservation-form input, #reservation-form textarea {border-radius: 4px;background-color: #ffffff; border: solid 1px #dcdcdc; box-shadow: none; min-height: 40px;}
.form-control.verror{border: 1px solid #aa5d52 !important;}
.date-picker {
  height: auto;
  max-height: 40px;
  background: white;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.3s 0s ease-in-out;
  transition: all 0.3s 0s ease-in-out;
  border-radius: 4px;
    border: solid 1px #dcdcdc !important;
}
.date-picker .input {
  width: 100%;
  height: 50px;
  font-size: 0;
  cursor: pointer;
}
.date-picker .input .result, .date-picker .input button {display: inline-block; vertical-align: top;}
.date-picker .input .result {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #434a4c;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.result span{font-size: 13px; letter-spacing: 1px; color: #717677; font-weight:normal; margin-right: 30px;}
.date-picker .input button {
  width: 50px;
  height: 50px;
  background-color: #8392A7;
  color: white;
  line-height: 50px;
  border: 0;
  font-size: 18px;
  padding: 0;
  padding-right: 30px;
}
.date-picker .calendar {position: relative; width: 100%; background: #fff; border-radius: 0px; overflow: hidden;}
.ui-datepicker-next:before{content: "\f138"; font-family: FontAwesome; font-style: normal; font-weight: normal; font-size: 18px;}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover{border: 1px solid #576a2f !important; background: #576a2f !important; color: #fff !important;}
.ui-datepicker-prev:before{content: "\f137"; font-family: FontAwesome; font-style: normal; font-weight: normal; font-size: 18px;}
/*.ui-state-hover{border:0px !important; left: 2px !important; top: 2px!important;}*/
.date-picker .ui-datepicker-inline {position: relative;width: 100%;}
.date-picker.open {max-height: 400px;}
.reservation-time select, .people-reservation select, .date-picker, #menu_selector{
  background-image: linear-gradient(45deg, transparent 50%, #4d4d4e 50%), linear-gradient(135deg, #4d4d4e 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 6px, 5px 1.5em;
  background-repeat: no-repeat;
  width: 100%;
  display: inline-block;
  border: none;
  /* height: 40px; */
  padding: 0px 5px;
  -webkit-appearance: none;
  background-color: #fff;
  cursor: pointer;
}
.reservation-time, .people-reservation{display: flex; justify-content: space-around; border-radius: 4px; border: solid 1px #dcdcdc; padding: 0px 7px; height: 40px; background-color: #fff; cursor: pointer;}
.reservation-time label, .people-reservation label{line-height: 40px; font-size: 13px; font-weight: bold; letter-spacing: 1px; color: #434a4c; margin-bottom: 0px;}
select:focus{outline: 0; box-shadow: none;}
.calendar-container{padding: 0;}
#smoking-table, #no-smoking-table{
    -webkit-appearance: none;
    width: 20px;
    min-height: 20px !important;
    border-radius: 4px;
    border: solid 1px #dcdcdc !important;
    display: inline-block;
    vertical-align: middle ;
}
#no-smoking-table:checked:after, #smoking-table:checked:after{content: " "; border-radius: 2px; margin: 4px auto; width: 12px; height: 12px; background-color: #576a2f; display: block;}
.smoking-check label{font-size: 13px; margin-bottom: 0; font-weight: bold; letter-spacing: 1px; margin-left: 8px; color: #434a4c; vertical-align: middle; line-height: 20px;}
#no-smoking-table{margin-left: 30px;}
/*delivery*/
.delivery-container{width: 100%; min-height: 780px; background-color: #fff; margin-top: 25px;}
.right-map{width: 100%;height: 780px;}
.delivery-scooter{position: absolute; bottom: 20px; right: 10px;}
.delivery-scooter img{max-width: 60px;}
.delivery-left-text{padding: 30px 20px 0px 20px;}
.delivery-left-text p{font-size: 15px; line-height: 1.4; letter-spacing: 1px; color: #717677; font-family: 'Lato', sans-serif;}
.delivery-left-text h3{font-size: 16px; font-weight: bold; letter-spacing: 1px; color: #434a4c; font-family: hypatia-sans-pro, sans-serif; margin-bottom: 0px;}
.delivery-left-text span{font-size: 15px; line-height: 1.4; letter-spacing: 1px; color: #717677; font-family: 'Lato', sans-serif;}
.delivery-info-wrap{position: relative; min-height: 780px;}
.delivery-map{padding:0;}
/*restaurants*/

.thessaloniki-map{min-height: 780px; width: 100%;}
.top-map{margin-top: 26px; margin-bottom: 80px;}


/*news*/
.news-container{padding:0;}
.news-bg-image{height: 500px; width: 100%; position: relative; display: flex; align-items: center;}
.ghost-a{
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.40);
}
.news-title{z-index: 4; display: block; width: 100%; text-align: center;}
.news-title h2{padding:0px 100px;}
.news-title h2 a{font-size: 24px; line-height: 1.33; letter-spacing: 4px; text-align: center; color: #ffffff; /*padding: 0px 130px;*/ font-family: hypatia-sans-pro, sans-serif; font-weight: 300;}
.news-list{margin-top: 30px;}
.load-more-btn{margin-top: 60px; text-align: center;}
.load-more-btn a{background-color: #aa5d52; padding: 12px 42px; border-radius: 20px; color: #fff; font-size: 13px; font-weight: bold; letter-spacing: 1.7px;}
.load-more-btn a:hover{background-color: #576a2f;}


/*food menu page*/
.menu-tabs{/*overflow: hidden;*/ padding-top: 40px; z-index: 2; position: relative;}
.food-menu-tabs{
  display: flex;
  /*justify-content: space-around;*/
  min-height: 50px;
  background-color: #434a4c;
  padding: 0px 20px;
  margin-bottom: 0px;
}
.food-menu-tabs li{padding: 0px 20px;}
.food-menu-tabs li a{
  font-size: 14px;
  font-weight: bold;
  line-height: 50px;
  letter-spacing: 1px;
  color: #ffffff;
  min-height: 50px;
  display: block;
  font-family: hypatia-sans-pro, sans-serif;
}
.food-menu-tabs li.active{background-color: #fff;}
.food-menu-tabs li.active a{color: #576a2f;}
.food-menu-tabs li:hover{background-color: #fff;}
.food-menu-tabs li:hover a{color: #576a2f;}
.tab-wrap{background-color: #ffffff; border: solid 1px #dcdcdc; padding-top: 80px; overflow: hidden; margin-top: -20px; padding-bottom: 70px;}
.menu-list ul{padding: 0; display: flex; justify-content: space-between;}
.menu-title{font-size: 24px; line-height: 1.33; letter-spacing: 4px; color: #434a4c; margin-bottom: 0px; font-family: hypatia-sans-pro, sans-serif;}
.menu-price{font-size: 16px; font-weight: bold; letter-spacing: 1px; color: #434a4c; font-family: hypatia-sans-pro, sans-serif;}
.menu-desc{font-size: 15px; line-height: 1.4; letter-spacing: 1px; color: #717677; margin-bottom: 25px; display: block; padding-right: 44px; }
.menu-list{border-bottom: 1px solid #dcdcdc; margin-bottom: 20px;}
.product-info p{  font-size: 15px; line-height: 1.4; letter-spacing: 1px; color: #717677; text-align: right; margin-top: 14px; display: block;}
#menu_selector{
  width: 100%;
  text-align: center;
  height: 40px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #434a4c;
  max-width: 200px;
  border: solid 1px #dcdcdc;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 20px;
  -webkit-appearance: none;
  padding: 0px 20px;
}
#menu_selector::placeholder{text-align: center !important; color: red !important;   text-align-last: center !important;}
select{ text-align:center; }
select option{ text-align:center; }

/*testimonials page*/
.testimonials-container{background-color: #ffffff; margin-top: 25px; border:1px solid #dcdcdc;}
.testimonial{border-left: 1px solid #dcdcdc; border-bottom: 1px solid #dcdcdc; min-height: 500px; position: relative; display: flex; align-items: center;}
.testimonial-text{padding:0px 140px;}
.posted-icon{padding-left: 40px; display: flex; justify-content: space-around;}
.post-icon{align-self: center; margin-top: 25px;}
.testimonial-text h2{ font-size: 24px; line-height: 1.33; letter-spacing: 4px; color: #434a4c;  font-family: hypatia-sans-pro, sans-serif;}
.posted-on{margin-top: 25px; display: block;font-size: 20px; color: #434a4c;}
.mobile-home-btn .ghost-a{background-color: transparent;}
/*single blog*/

/*single post */
#single-post{overflow-x: hidden; margin-top: 130px; background-color: #fafafa;}
.single-post-bg{background-color: #1c1c1c;}
.single-post-img{background-position: center; background-size: cover; background-repeat: no-repeat; width: 100%; height: 650px;}
.single-post-content{margin-top: 80px;}
.post-title>.post-count-images-sep{color: #575152;}
.post-title .post-date-tag{color: #575152;}
.post-title h1{font-size: 36px; line-height: 1.09; letter-spacing: 2.8px; color: #434a4c; font-family: hypatia-sans-pro, sans-serif; margin-top: 5px;}
.post-share-it{min-height: 120px; border-top: 1px solid #979797;}
.post-share-it h3{font-size: 14px;font-weight: 900; color: #221e1f; margin-bottom: 5px; margin-top: 25px;}
.post-share-it ul{padding: 0px;}
.post-share-it ul li{float: left; width: 40px; height: 40px; margin-right: 5px; border-radius: 20px; line-height: 40px;}
.share-fb{background-color: #39559e;}
.share-twitter{ background-color: #00a9f2;}
.share-youtube{ background-color: #d30000;}
.share-google{  background-color: #e14929;}
.post-share-it ul li a{
   display: block;
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 40px;}

.single-post-text p{  font-size: 17px; line-height: 1.29; letter-spacing: 1px; color: #717677;}
.single-post-text img{display: block; margin:0 auto; margin-top: 20px; margin-bottom: 20px;}
.single-post-body{overflow: hidden; padding-bottom: 87px;}
.more-articles h3{font-size: 30px; font-weight: bold; letter-spacing: 4px; color: #576a2f; font-family: hypatia-sans-pro, sans-serif; text-align: center;}
.single-post-wrap{margin-top: 20px;}
.more-posts{/*position: relative;*/ height: 100%; min-height: 250px; margin-bottom: 80px;}
.read-more-bg{width: 100%; min-height: 250px; position: absolute; left: 0; right: 0; background-position: center; background-size: cover; background-repeat: no-repeat; display: flex; justify-content: center;}
.posts-list-tlt{display: flex; align-items: center;}
.posts-list-tlt h2{display: block; text-align: center; padding: 0px 40px;}

.posts-list-tlt h2 a{font-size: 24px; line-height: 1.33; letter-spacing: 4px; text-align: center; color: #ffffff; position: relative; z-index: 99; font-family: hypatia-sans-pro, sans-serif; font-weight: 300;}
/*.posts-list-tlt h2 a:hover{color: #de0f17;}*/
.more-post-title{margin-top: 30px; margin-bottom: 30px;}
.more-post-title h2{font-size: 36px; font-weight: 900; line-height: 1.28; color: #221e1f;}


/*mobile homepage*/
.navbar-header.dark-menu{ background-color: #1d1e1e; height: 70px;}
.mobile-home-buttons, .mobile-logo, .mobile-top-menu{display: none;}
.mobile-home-buttons{margin-top: 40px !important;}


/*instagram*/
#instafeed {
  width: 100%;
}
#instafeed a {
  display: block;
  float: left;
  position: relative;
  width: 20%;

}
#instafeed a:hover:before{
     content: "\f16d";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #fff;
    font-size: 34px;
    text-align: center;
    z-index: 2;
    position: absolute;
    top:45%;
    left: 0;
    right: 0;
    opacity: 0.75;
}
#instafeed a:hover:after{
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  content: "";
    -webkit-animation-name: specialFadeIn;
          animation-name: specialFadeIn;
  -webkit-animation-delay: 30ms;
          animation-delay: 30ms;
  -webkit-animation-duration: 200ms;
          animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  background-color: rgba(0, 0, 0, 0.40);
}

/*newsletter*/
span.ajax-loading {display: inline-block; margin-left: 10px;}
.c-validation{max-width: 496px; width: 100%; text-align: center; color: #aa5d52;}
.newsletterReponse {max-width: 496px; width: 100%; font-size: 1.2em; text-align: center; color: #576a2f;}
.newsletter-form {min-height: 73px;}



@media screen and (min-width: 580px) {
  #instafeed a {
    width: 20%;
  }
}
#instafeed a img {
  display: block;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1280px) {
    .bg-button{margin-top: 140px;}
    .testimonial-text{padding: 0px 80px;}
}
  .mobile-home-footer{display: none;}